import icon180 from '@/assets/icons/180x180.png';
import icon16 from '@/assets/icons/favicon-16x16.png';
import icon32 from '@/assets/icons/favicon-32x32.png';
import iconSvg from '@/assets/icons/icon.svg';

import { useTheme } from '@chakra-ui/react';
import { AppHead } from '@codeer/handypark-web-common';

export const HeadTags = () => {
  const theme = useTheme();

  const primaryMain = theme.colors.primary.main;

  return (
    <AppHead>
      <meta name='description' content='Description' />
      <link rel='apple-touch-icon' sizes='180x180' href={icon180} />
      <link rel='icon' type='image/png' sizes='32x32' href={icon32} />
      <link rel='icon' type='image/png' sizes='16x16' href={icon16} />
      <link rel='mask-icon' href={iconSvg} color={primaryMain} />
      <meta name='msapplication-TileColor' content={primaryMain} />
      <meta name='theme-color' content={primaryMain} />
    </AppHead>
  );
};
